<template>
<div>
    <v-dialog v-model="showDialog" width="500" persistent scrollable>
        <v-card :loading="loading" :disabled="loading">
            <v-card-title>{{ editDialog ? "Termin bearbeiten" : "Termin hinzufügen" }}</v-card-title>
            <v-tabs v-model="currentTab" class="mb-2" grow color="primaryLight">
                <v-tab>Termindetails</v-tab>
                <v-tab>Teilnehmer</v-tab>
            </v-tabs>
            <v-card-text v-if="currentTab == 0">
                <p>Termininformationen</p>
                <v-row>
                    <v-col cols="12">
                        <DatePicker v-model="date"></DatePicker>
                    </v-col>
                    <v-col cols="6">
                        <TimePicker v-model="startTime" label="Start"></TimePicker>
                    </v-col>
                    <v-col cols="6">
                        <TimePicker v-model="endTime" label="Ende"></TimePicker>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field v-model="attendees" label="Teilnehmeranzahl" prepend-icon="mdi-account-group" color="primaryLight" type="number" hide-details></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field v-model="price" label="Preis (€)" prepend-icon="mdi-cash" color="primaryLight" type="number" hide-details></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field v-model="teachingAids" label="Lehrmittel (€)" prepend-icon="mdi-cash" color="primaryLight" type="number" hide-details></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                            v-model="teacher"
                            :items="teacherItems"
                            item-text="firstName"
                            label="Schulungsleiter*in"
                            prepend-icon="mdi-school"
                            color="primaryLight"
                            return-object
                            hide-details
                            type="number">
                            <template slot="selection" slot-scope="data">
                                <v-flex>
                                    <v-avatar size="25px" class="mr-1">
                                        <v-img v-if="data.item.picture" :src="data.item.picture" />
                                        <v-icon v-else>mdi-account</v-icon>
                                    </v-avatar>
                                    {{ data.item.firstName }} {{ data.item.lastName }}
                                </v-flex>
                            </template>
                            <template slot="item" slot-scope="data">
                                <v-list-item-avatar>
                                    <v-img v-if="data.item.picture" :src="data.item.picture" />
                                    <v-icon v-else>mdi-account</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ data.item.firstName }} {{ data.item.lastName }}</v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12" v-if="!appointment">
                        <v-select
                            v-model="module"
                            :items="$store.state.modules.modules"
                            item-text="name"
                            item-value="number"
                            label="Modul"
                            prepend-icon="mdi-puzzle"
                            color="primaryLight"
                            hide-details
                            type="number"></v-select>
                    </v-col>
                </v-row>
                <p class="mt-8">Standortinformationen</p>
                <v-row>
                    <v-col cols="8">
                        <v-text-field v-model="address.street" label="Straße" prepend-icon="mdi-road-variant" hide-details color="primaryLight"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                        <v-text-field v-model="address.number" label="Nr." hide-details color="primaryLight"></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="suggestedAddress" class="py-0">
                        <v-card @click="setSuggestedAddress" outlined class="mt-2">
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-icon>mdi-map-marker</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ suggestedAddress.street }} {{ suggestedAddress.number }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ suggestedAddress.postcode }} {{ suggestedAddress.location }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </v-col>
                    <v-col cols="5">
                        <v-text-field v-model="address.postcode" label="PLZ" prepend-icon="mdi-map-marker" hide-details color="primaryLight"></v-text-field>
                    </v-col>
                    <v-col cols="7">
                        <v-text-field v-model="address.location" label="Ort" hide-details color="primaryLight"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="address.building" label="Raumbeschreibung" prepend-icon="mdi-domain" hide-details color="primaryLight"></v-text-field>
                    </v-col>
                </v-row>
                <p class="mt-8">Weiteres</p>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            v-model="info"
                            label="Meldung / Hinweis (optional)"
                            prepend-icon="mdi-information"
                            rows="2"
                            hint="Eine Meldung zu diesem Termin kann verwendet werden um die Teilnehmer:innen über besonderheiten bei dem Termin zu Informieren."
                            persistent-hint></v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-else-if="currentTab == 1">
                <v-checkbox v-model="customSlots" label="Dieser Termin ist nur für bestimmte Unternehmen" hide-details></v-checkbox>
                <div v-if="customSlots" class="mt-4">
                    <v-card v-for="customSlotCompany in customSlotCompanies" :key="customSlotCompany.company.id" class="my-2">
                        <v-card-text>
                            <span>{{ customSlotCompany.company.name }}</span>
                            <v-slider v-model="customSlotCompany.slots" prepend-icon="mdi-account-multiple" :max="attendees" min="0" thumb-label color="primary" hide-details></v-slider>
                            <span v-if="customSlotCompany.slots == 0" class="text-caption">
                                Dieses Unternehmen kann keine Teilnehmer:innen für diesen Termin buchen.
                            </span>
                            <span v-else class="text-caption">
                                Dieses Unternehmen kann {{ customSlotCompany.slots == 1 ? "ein:e Teilnehmer:in" : (customSlotCompany.slots + " Teilnehmer:innen") }} für diesen Termin buchen.
                            </span>
                        </v-card-text>
                    </v-card>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="showDialog = false" text>Abbrechen</v-btn>
                <v-btn :disabled="!requiredInput" @click="save" color="primaryLight" text>OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>
<script>
import DatePicker from "../form-items/DatePicker.vue"
import TimePicker from "../form-items/TimePicker.vue"
import { format } from "date-fns"
import { Appointment } from "@/classes/Appointment"
import { Address } from "@/classes/Address"

export default {
    data: () => ({
        currentTab: 0,
        loading: false,
        showDialog: false,
        date: format(new Date(), "yyyy-MM-dd"),
        startTime: format(new Date(), "08:00"),
        endTime: format(new Date(), "10:00"),
        attendees: "",
        price: "",
        teachingAids: "",
        teacher: "",
        module: 0,
        address: {
            street: "",
            number: "",
            postcode: "",
            location: "",
            building: ""
        },
        infoHTML: "",
        customSlots: false,
        customSlotCompanies: []
    }),
    props: {
        appointment: {
            type: Object,
            default: null
        },
        active: {
            type: Number,
            default: 0
        }
    },
    methods: {
        floatToPriceString(val) {
            return val.toFixed(2).toString()
        },
        priceStringToFloat(val) {
            return parseFloat(val.replace(",", "."))
        },
        async save() {
            if (!this.requiredInput) {
                return
            }
            this.loading = true
            try {
                let appointment = this.appointment
                if (!appointment) {
                    appointment = new Appointment()
                    appointment.module = this.module
                }
                appointment.from = new Date(`${this.date} ${this.startTime}`)
                appointment.to = new Date(`${this.date} ${this.endTime}`)
                appointment.maxAttendees = parseInt(this.attendees)
                appointment.address = new Address()
                appointment.address.street = this.address.street
                appointment.address.number = this.address.number
                appointment.address.postcode = this.address.postcode
                appointment.address.location = this.address.location
                appointment.address.building = this.address.building
                appointment.price = this.priceStringToFloat(this.price)
                appointment.teachingAids = this.priceStringToFloat(this.teachingAids)
                appointment.teacher = this.teacher
                appointment.info = this.infoHTML
                if (this.customSlots) {
                    const slots = {}
                    for (const customSlot of this.customSlotCompanies) {
                        if (customSlot.slots > 0) {
                            slots[customSlot.company.id] = customSlot.slots
                        }
                    }
                    appointment.slots = slots
                } else {
                    appointment.slots = null
                }
                await appointment.toFirestore()
                this.showDialog = false
            } catch (err) {
                console.error(err)
                this.$store.commit("showAlert", {
                    title: "Termin hinzufügen",
                    message: "Es ist ein Fehler beim Hinzufügen des Termins aufgetreten. Bitte versuchen Sie es später erneut."
                })
            }
            this.loading = false
        },
        setSuggestedAddress() {
            this.address.street = this.suggestedAddress.street
            this.address.number = this.suggestedAddress.number
            this.address.postcode = this.suggestedAddress.postcode
            this.address.location = this.suggestedAddress.location
            this.address.building = this.suggestedAddress?.building || ""
        },
        async loadCompanies() {
            const companies = await this.$store.dispatch("attachCompanies")
            console.log(companies)
        },
        getSlotsForCompany(companyID) {
            if (this.appointment && this.appointment.slots) {
                return this.appointment.slots[companyID] || 0
            }
            return null
        }
    },
    computed: {
        info: {
            set(val) {
                this.infoHTML = val.replace(/(?:\r\n|\r|\n)/g, "<br>")
            },
            get() {
                if (!this.infoHTML) {
                    return ""
                }
                return this.infoHTML.replace(/<br ?\/?>/g, "\n")
            }
        },
        teacherItems() {
            const items = []
            for (const teacher of this.$store.state.user.teachers) {
                items.push(teacher)
            }
            return items
        },
        suggestedAddress() {
            if (this.address.street == "" && this.address.location == "") {
                return null
            }
            for (const appointment of this.$store.state.appointment.appointments) {
                if (
                    appointment.address.street == this.address.street &&
                    appointment.address.number == this.address.number &&
                    appointment.address.postcode == this.address.postcode &&
                    appointment.address.location == this.address.location &&
                    appointment.address.building == this.address.building
                ) {
                    return null
                } else if (this.address.street && appointment.address.street.toLowerCase().startsWith(this.address.street.toLowerCase())) {
                    if (this.address.number == "") {
                        return appointment.address
                    } else if (appointment.address.number.toLowerCase().startsWith(this.address.number.toLowerCase())) {
                        return appointment.address
                    }
                } else if (this.address.location && appointment.address.location.toLowerCase().startsWith(this.address.location.toLowerCase())) {
                    if (this.address.street == "") {
                        return appointment.address
                    } else if (appointment.address.street.toLowerCase().startsWith(this.address.street.toLowerCase())) {
                        return appointment.address
                    }
                }
            }
            return null
        },
        requiredInput() {
            if (!this.attendees || isNaN(parseInt(this.attendees))) {
                return false
            }
            if (!this.address.street || !this.address.number || !this.address.postcode || !this.address.location || !this.address.building) {
                return false
            }
            if (!this.attendees || isNaN(parseFloat(this.price))) {
                return false
            }
            if (!this.teacher) {
                return false
            }
            if (!this.module || isNaN(parseInt(this.module))) {
                return false
            }
            return true
        },
        editDialog() {
            return false
        }
    },
    watch: {
        customSlots(val) {
            if (val) {
                this.customSlotCompanies = []
                for (const company of this.$store.state.company.companies) {
                    this.customSlotCompanies.push({
                        company: company,
                        slots: this.getSlotsForCompany(company.id) || 0
                    })
                }
            }
        },
        active() {
            this.showDialog = true
            this.$store.dispatch("attachTeachers")
            if (this.appointment) {
                this.date = format(this.appointment.from, "yyyy-MM-dd")
                this.startTime = format(this.appointment.from, "HH:mm")
                this.endTime = format(this.appointment.to, "HH:mm")
                this.attendees = this.appointment.maxAttendees
                this.address.street = this.appointment.address.street
                this.address.number = this.appointment.address.number
                this.address.postcode = this.appointment.address.postcode
                this.address.location = this.appointment.address.location
                this.address.building = this.appointment.address?.building
                this.price = this.floatToPriceString(this.appointment.price)
                this.teachingAids = this.floatToPriceString(this.appointment.teachingAids)
                this.teacher = this.appointment.teacher
                this.module = this.appointment.module
                this.infoHTML = this.appointment.info
                if (this.appointment.slots) {
                    this.customSlots = true
                } else {
                    this.customSlots = false
                }
            } else {
                this.date = format(new Date(), "yyyy-MM-dd")
                this.startTime = "08:00"
                this.endTime = "15:30"
                this.address.street = ""
                this.address.number = ""
                this.address.postcode = ""
                this.address.location = ""
                this.address.building = ""
                this.teacher = ""
                this.module = 0
                this.infoHTML = ""
                this.customSlots = false
                const setSettingsVals = async () => {
                    this.price = this.floatToPriceString(await this.$store.dispatch("getSetting", { key: "price", def: 1200 }))
                    this.teachingAids = this.floatToPriceString(await this.$store.dispatch("getSetting", { key: "teachingAids", def: 7.60 }))
                    this.attendees = (await this.$store.dispatch("getSetting", { key: "maxAttendees", def: 40 })).toString()
                }
                setSettingsVals()
            }
        }
    },
    components: { DatePicker, TimePicker }
}
</script>
