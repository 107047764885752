<template>
    <div>
        <p v-if="values && values.length <= 0" style="width:100%; text-align: center;">Es wurden keine Termine mit Ihrer Suche gefunden. </p>
        <Calendar
            :data-source="values"
            :display-header="true"
            :render-style="style"
            :week-start="1"
            @click-day="ShowDayEvents"
        />
    </div>
  </template>
  
<script>
import Calendar from "v-year-calendar"

const countKeyInArray = (array, classifier) => {
    classifier = classifier || String
    return array.reduce((counter, item) => {
        const p = classifier(item)
        counter[p] = counter.p ? counter[p] + 1 : 1
        return counter
    }, {})
}
  
export default {
    name: "DashboardCalendarYear",
    components: {
        Calendar
    },
    props: {
        appointments: Array
    },
    data() {
        return {
            currentDate: null,
            currentEvents: null,
            style: "background"
        }
    },
  
    computed: {
        values() {
            return this.generateEvents(this.appointments)
        },
        valueCounts() {
            return this.values && this.values.length > 0
                ? countKeyInArray(this.values, (item) => item.location)
                : null
        }
    },
    methods: {
        generateEvents (appointments) {
            let id = 1
            let events = []

            for (const appointment of appointments) {          
                events.push({
                    color: "#9c0047",
                    endDate: appointment.to,
                    id: id++,
                    startDate: appointment.from,
                    appointment
                })   
            }
            return events
        },
        ShowDayEvents (event) {
            const appointmentId =  event.events[0]?.appointment?.id
            if(appointmentId){
                this.$router.push(`/appointment/${appointmentId}`)
            }
        }
    }
}
</script>

<style lang="css" scoped>
.calendar {
  overflow-x: initial;
}

.calendar >>> .months-container .month-container {
  height: 278px;
  margin-bottom: 24px;
}

.calendar >>> table.month {
  background-color: white;
  border: 1px solid rgb(224, 224, 224);
  height: 100%;
}

@media (prefers-color-scheme: dark) {
  .calendar >>> table.month {
    background-color: rgb(34, 34, 34); /* Set a darker background for dark mode */
    color: white; /* Ensure text is visible */
  }
}

.calendar >>> table.month th.month-title {
  background-color: rgb(224, 224, 224);
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

@media (prefers-color-scheme: dark) {
  .calendar >>> table.month th.month-title {
    background-color: rgb(54, 54, 54); /* Adjust for dark mode */
  }
}

.calendar >>> table.month th.day-header {
  padding: 8px;
  color: rgb(124, 124, 124);
  font-size: 12px;
  font-weight: 500;
}

@media (prefers-color-scheme: dark) {
  .calendar >>> table.month th.day-header {
    color: rgb(200, 200, 200); /* Lighter text for dark mode */
  }
}

.calendar >>> table.month td.day {
  height: 16px;
}

.calendar >>> table.month td.day .day-content {
  padding: 8px;
  border-radius: 0;
}
</style>
