import { Appointment } from "@/classes/Appointment"
import { firestore, functions } from "@/firebase"
import { startOfDay, subDays } from "date-fns"
import { onSnapshot, collection, orderBy, query, where, collectionGroup } from "firebase/firestore"
import store from ".."
import { httpsCallable } from "firebase/functions"
import { format } from "date-fns"

const appointmentRef = collection(firestore, "appointment")

const state = {
    appointments: [],
    myAttendees: [],
    loading: true,
    firebaseListeners: {
        appointments: null,
        myAttendees: null
    },
    autoBookItems: {},
    autoBookLoading: false
}
const getters = {
    
}
const actions = {
    async setAutoBookItems({ state }, { company }) {
        state.autoBookLoading = true
        const getAutoBookingList = httpsCallable(functions, "getAutoBookingList")
        try {
            const response = await getAutoBookingList({company})
            const autoBookItems = {}
            for (const item of response?.data?.items) {
                for (const appointment of item.appointments) {
                    if (!autoBookItems[appointment.appointment]) {
                        autoBookItems[appointment.appointment] = []
                    }
                    let before = ""
                    let after = ""
                    if (appointment.before.type == "free") {
                        before = "Frei"
                    } else {
                        before = `${format(new Date(appointment.before.from), "HH:mm")} - ${format(new Date(appointment.before.to), "HH:mm")}`
                    }
                    if (appointment.after.type == "free") {
                        after = "Frei"
                    } else {
                        before = `${format(new Date(appointment.after.from), "HH:mm")} - ${format(new Date(appointment.after.to), "HH:mm")}`
                    }
                    autoBookItems[appointment.appointment].push({
                        firstName: item.driverInfo.firstName,
                        lastName: item.driverInfo.lastName,
                        before: before,
                        after: after,
                        personalID: item.driverInfo.personalID,
                        risk: item.driverInfo.risk.toFixed(2),
                        id: item.driverID,
                        appointmentID: appointment.appointment
                    })
                }
            }
            // this.$store.dispatch("setAutoBookItems", {
            //     autoBookItems: this.autoBookItems
            // })
            state.autoBookItems = autoBookItems
            console.log("🚀 ~ setAutoBookItems ~ autoBookItems:", autoBookItems)
            state.autoBookLoading = false
        } catch (err) {
            console.log("🚀 ~ getAutoBookingList ~ err:", err)
            console.error(err)
        }
    },
    attachAppointments({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firebaseListeners.appointments) {
                state.loading = false
                resolve(state.appointments)
                return
            }
            state.loading = true
            let snapshotQuery
            if (store.state.auth.userClaims.permissions.appointments || store.state.auth.userClaims.permissions.companies || store.state.auth.userClaims.dispatcherFor.length > 0) {
                snapshotQuery = query(
                    appointmentRef,
                    where("from", ">=", startOfDay(subDays(new Date(), 90))),
                    orderBy("from", "asc")
                )
            } else {
                snapshotQuery = query(
                    appointmentRef,
                    where("attendeeSubs", "array-contains", store.state.auth.profile.sub),
                    orderBy("from", "asc")
                )
            }

            state.firebaseListeners.appointments = onSnapshot(snapshotQuery, (snapshot) => {
                state.appointments = []
                for (const doc of snapshot.docs) {                    
                    state.appointments.push(new Appointment(doc))
                }
                state.loading = false
                resolve(state.appointments)
            }, (error) => {
                state.loading = false
                reject(error)
            })
        })
    },
    detatchAppointments({ state }) {
        if (state.firebaseListeners.appointments) {
            state.firebaseListeners.appointments()
            state.firebaseListeners.appointments = null
            state.loading = true
        }
    },
    attachMyAttendees({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firebaseListeners.myAttendees) {
                resolve(state.myAttendees)
                return
            }
            const attendeeRef = query(collectionGroup(firestore, "attendee"), where("sub", "==", store.state.auth.profile.sub))
            state.firebaseListeners.myAttendees = onSnapshot(attendeeRef, (snapshot) => {
                for (const doc of snapshot.docs) {
                    const data = doc.data()
                    data.appointmentID = doc.ref.parent.parent.id                    
                    state.myAttendees.push(data)
                }
                resolve(state.myAttendees)
            }, (err) => {
                reject(err)
            })
        })
    }
}
export default { state, getters, actions }