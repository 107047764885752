<template>
<v-card outlined @click="clickAppointment" :ripple="clickable" :class="clickable ? '' : 'v-card-non-clickable'">
    <div v-if="attendeeInfo">
        <v-alert v-if="appointment.canceled" type="success" flat dense text class="ma-1">Teilnahme nicht erforderlich</v-alert>
        <v-alert v-else-if="!appointment.closed" type="info" flat dense text class="ma-1">Teilnahme ausstehend</v-alert>
        <v-alert v-else-if="attendeeInfo.attended" type="success" flat dense text class="ma-1">Teilgenommen</v-alert>
        <v-alert v-else-if="!attendeeInfo.attended" type="error" flat dense text class="ma-1">Verpasst / nicht Teilgenommen</v-alert>
    </div>
    <v-alert v-if="appointment.registered" type="info" flat dense text class="ma-1">Angemeldet bei der Bezirksregierung </v-alert>
    <v-alert v-if="appointment.roomBooked" type="success" flat dense text class="ma-1">Raum gebucht </v-alert>
    <div v-if="$vuetify.breakpoint.name == 'xs'" class="pa-4">
        <v-row>
            <v-col cols="6">
                <b>Ort und Datum</b>
            </v-col>
            <v-col cols="6" class="text-right">
                <b>Modul</b>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                {{ formatDayName(appointment.from) }}
                <br>
                {{ formatDate(appointment.from) }}
                <br>
                {{ formatTime(appointment.from) }} - {{ formatTime(appointment.to) }}
                <br>
                {{ appointment.address.location }}
            </v-col>
            <v-col cols="6" class="text-right">
                <v-chip>Modul {{appointment.module}}</v-chip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" class="text-left">
                <b>Schulungsleiter</b>
            </v-col>
            <v-col cols="6" class="text-right">
                <b>Teilnehmer & Preis</b>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" class="text-left">
                {{ appointment.teacher.firstName }} {{ appointment.teacher.lastName }}
            </v-col>
            <v-col cols="6" class="text-right">
                {{ appointment.attendees }}/{{ appointment.maxAttendees }} Teilnehmer
                <br>
                ~ {{ formatPrice(appointment.minPrice) }} € - {{ formatPrice(appointment.maxPrice) }} €
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-information</v-icon>
                        </v-btn>
                    </template>
                    <span>Bei den Preisen handelt es sich um eine unverbindliche Preisschätzung, die jederzeit verändert werden kann.</span>
                </v-tooltip>
                <br>
                {{ formatPrice(appointment.teachingAids) }} € Lehrmittelkosten
            </v-col>
        </v-row>
        <v-row v-if="appointment.canceled">
            <v-col cols="12">
                <v-alert text dense color="error" type="warning" class="ma-0">Dieser Termin wurde abgesagt.</v-alert>
            </v-col>
        </v-row>
    </div>
    <div v-else class="pa-4">
        <v-row>
            <v-col cols="3">
                <b>Ort und Datum</b>
            </v-col>
            <v-col cols="3" class="text-center">
                <b>Modul</b>
            </v-col>
            <v-col cols="3" class="text-center">
                <b>Schulungsleiter</b>
            </v-col>
            <v-col cols="3" class="text-right">
                <b>Teilnehmer & Preis</b>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
                {{ formatDayName(appointment.from) }}
                <br>
                {{ formatDate(appointment.from) }}
                <br>
                {{ formatTime(appointment.from) }} - {{ formatTime(appointment.to) }}
                <br>
                {{ appointment.address.location }}
            </v-col>
            <v-col cols="3" class="text-center">
                <v-chip>Modul {{appointment.module}}</v-chip>
            </v-col>
            <v-col cols="3" class="text-center">
                {{ appointment.teacher.firstName }} {{ appointment.teacher.lastName }}
            </v-col>
            <v-col cols="3" class="text-right">
                {{ appointment.attendeeRecommendationIDs.length }} Vorgemerkt
                <br>
                {{ appointment.attendees }}/{{ appointment.maxAttendees }} Teilnehmer
                <br>
                ~ {{ formatPrice(appointment.minPrice) }} € - {{ formatPrice(appointment.maxPrice) }} €
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-information</v-icon>
                        </v-btn>
                    </template>
                    <span>Bei den Preisen handelt es sich um eine unverbindliche Preisschätzung, die jederzeit verändert werden kann.</span>
                </v-tooltip>
                <br>
                {{ formatPrice(appointment.teachingAids) }} € Lehrmittelkosten
            </v-col>
        </v-row>
        <v-row v-if="appointment.canceled">
            <v-col cols="12">
                <v-alert text dense color="error" type="warning" class="ma-0">Dieser Termin wurde abgesagt. In den Termininformationen finden Sie unter Umständen weitere Details.</v-alert>
            </v-col>
        </v-row>
    </div>
    <slot></slot>
</v-card>
</template>
<script>
import { format } from "date-fns"

export default {
    props: {
        appointment: Object,
        clickable: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        formatPrice(price) {
            return (Math.round(price * 100) / 100).toFixed(2).replace(".", ",")
        },
        clickAppointment() {
            this.$emit("click", this.appointment)
        },
        formatDayName(time) {
            const dayNames = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]
            return dayNames[time.getDay()]
        },
        formatDate(time) {
            return format(time, "dd.MM.yyyy")
        },
        formatTime(time) {
            return format(time, "HH:mm")
        }
    },
    computed: {
        attendeeInfo() {
            for (const attendee of this.$store.state.appointment.myAttendees) {
                if (attendee.appointmentID == this.appointment.id) {
                    return attendee
                }
            }
            return null
        }
    }
}
</script>
<style>
.v-card-non-clickable.v-card--link {
    cursor: default;
}
.v-card-non-clickable.v-card--link:before {
    background: none;
}
</style>